import React, { forwardRef } from "react";
import ContactFormTwo from "./ContactFormTwo";

const ContactUs = forwardRef((props, ref) => (
  <section className="contact-us white-bg" id="contact">
    <div className="container" style={{ backgroundColor: "#3D3D3D" }}>
      <ContactFormTwo />
    </div>
  </section>
));

export default ContactUs;
