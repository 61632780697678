import React from "react";

const HeadingSection = ({ title, tagline, classAppend, font, children }) => {
  return (
    <div
      className={"col-sm-8 section-heading text-white" + (classAppend ? classAppend : "")}
    >
      <h2
        // eslint-disable-next-line no-useless-concat
        className={font ? font : ""+"text-white"}
         
      >
        {title}
      </h2>
      {tagline && (
        <p style={{ textAlign: 'justify center', textJustify: 'inter-word', fontSize: '16px' }} 
          className={"text-white " + (font ? font : "")}
           
        >
          {`- ${tagline} -`}
        </p >
      )}
      {children ? (
        <p
          
          className="mt-30"
        >
          {children}
        </p>
      ) : null}
    </div>
  );
};

export default HeadingSection;
