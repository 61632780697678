import React from "react";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
// import Icofont from "react-icofont";

const TestimonialItem = ({ id,detail, title, image }) => {
  
  
  return(
  
  <div className="testimonial-item">
    <center>
    <div style={{ background: `url(${require("../../assets/images/"+image)}) center center / cover scroll no-repeat`,   minHeight:'440px',   maxWidth:'650px'}}   > 
    </div>
    <br/>
    <h3 className="text-white">{title}</h3>
    <p className="text-white">{detail}</p>
      <Link to={`${process.env.PUBLIC_URL}/projects/${id}`} className="btn btn-sm btn-white btn-square btn-animate mt-8">
        <span>
          Read more <Icofont icon="simple-right" />
        </span>
      </Link>
      <br/>
      <br/>
      <br/>
    </center>
  </div>
)};

export default TestimonialItem;
