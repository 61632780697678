import React from "react";
import dataTravel from "../../data/WhyChooseUs/why-travel-data.json";
// import img from "../../assets/images/slides/main.png";
import Icofont from "react-icofont";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialItem from "../Testimonials/AppStyle";

const AboutAppStyle = () => {
  const settings = {
    dots: false,
    centerMode: true,
    centerPadding: "0",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slick testimonial",
    // autoplay: {
    //   delay: 1000,
    //   disableOnInteraction: false,
    // },
  };
  return (
    <section className="main-section dark-bg" id="feature">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 section-heading">
            <h2 className="font-500 text-white">The Amenities, So Countless</h2>
            <p style={{ fontSize: "16px" }}>
              Carefully architected to peculiarize all essential ingredients of
              life that you might want in and around your dream home. It
              provides a variety of luxurious living choices to help you create
              the life you have dreamed of. {" "}
            </p>
          </div>
        </div>
        <div className="row mt-50">
          <div className="col-md-3 col-sm-12">
            <div className="pt-100 pb-100 float-sm-left">
              {dataTravel
                .filter((v, i) => i < 5)
                .map((feature, i) => (
                  <div
                    className="feature-box text-center text-sm-start"
                    key={feature.id}
                  >
                    <div className="float-sm-left text-center text-sm-start">
                      <Icofont
                        icon={feature.icon}
                        className="font-40px yellow-icon"
                      />
                    </div>
                    <div className="float-none float-sm-right">
                      <h4 className="mt-0 text-white">{feature.title}</h4>
                      <p style={{ color: "transparent" }}>{feature.text}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <Slider {...settings}>
              {dataTravel.map((dataTravel) => (
                <TestimonialItem
                  key={dataTravel.id}
                  icon={dataTravel.icon}
                  title={dataTravel.title}
                  image={dataTravel.image}
                ></TestimonialItem>
              ))}
            </Slider>
            {/* <img className="img-fluid" src={img} alt="" /> */}
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="pt-100 pb-100 float-sm-left">
              {dataTravel
                .filter((v, i) => i > 4)
                .map((feature, i) => (
                  <div
                    className="feature-box-right text-center text-sm-end"
                    key={feature.id}
                  >
                    <div className="float-sm-right text-center text-sm-end">
                      <Icofont
                        icon={feature.icon}
                        className="font-40px yellow-icon"
                      />
                    </div>
                    <div className="float-sm-left">
                      <h4 className="mt-0 text-white">{feature.title}</h4>
                      <p style={{ color: "transparent" }}>{feature.text}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutAppStyle;
