import React from "react";

const WelcomeItem = () => (
  <section>
    <div className=" ">
      <div className="travel-bg-left bg-flex col-md-6"></div>
      <div className="col-about-right col-md-6 offset-md-6">
        <h2 className="font-500">A Design, So Ageless</h2>
        <div className="mt-30">
          <p>
            In this rapid age of changing architecture, we provide you with a
            futuristic design that will remain ageless irrespective of modern
            trends. This edifice has an ideal contemporary design and an urban
            smart living lifestyle that will never go out of style as it is
            scrutinized in correspondence with the needs of local and
            international habitats.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default WelcomeItem;
