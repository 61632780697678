import React from "react";
import Swiper from "react-id-swiper"; 
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderSeven"; 
import dataNav from "../../data/Navbar/navbar-data.json";
import Icofont from "react-icofont";
import CTAOne from "../../components/CTA/CTAOne";
import FooterOne from "../../components/Footer/FooterOne"; 
import dataGallery from "../../data/Gallery/gallery-construction-data.json";
import { useParams } from "react-router-dom"; 
import BlogSlider from "../../components/Blog/BlogSlider"; 
import { useEffect } from "react";
import { useState } from "react";

const SinglePortfolio = () => {
  const [project, setProject] = useState(null)
  const { id } = useParams() 
  const params = {
    navigation: {
      nextEl: ".portfolio-slider-next",
      prevEl: ".portfolio-slider-prev",
    },
    effect: "fade",
    autoplay: {
      delay: 2500,
    },
  };
  useEffect(() => {
    setProject(dataGallery.find(item => item.id === Number(id)));
    
  }, [id])
  
 if(project === null) return <Loader />

  return (
    <Loader>
      <HeaderOne data={dataNav}/> 
      <section
        style={{ background: `url(${require("../../assets/images/"+project.titleImage)})`, backgroundSize: 'auto'}}
        className="title-hero-bg"
        data-stellar-background-ratio="0.2"
      >
        <div className="container">
          <div className="page-title text-center">
            <h1>{ project.title}</h1> 
          </div>
        </div>
      </section>
      <section className="pt-100 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-md-8 portfolio-left">
              <div className="portfolio-slider">
                <Swiper {...params}>
                  <div className="item">
                    <img className="img-fluid" src={require("../../assets/images/" + project.image)} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={require("../../assets/images/" + project.image2)} alt="" />
                  </div>
                  <div className="item">
                    <img className="img-fluid" src={require("../../assets/images/" + project.image3)} alt="" />
                  </div>
                </Swiper>
              </div>
               
            </div>
            <div className="col-md-4 portfolio-right">
              <ul className="project-detail-box">
                <li>
                  <strong>Project</strong>{project.title}
                </li>
                <li>
                  <strong>Date</strong>{project.date}
                </li>
                <li>
                  <strong>Website URL</strong>
                  <a href={process.env.PUBLIC_URL}>{project.website}</a>
                </li>
              </ul> 
              <div className="post-controls">
                <div className="post-share">
                  <ul>
                    <li>
                        <a href={process.env.PUBLIC_URL} target="_blank" rel="noopener noreferrer">
                          <Icofont icon='facebook' />
                        </a>
                         
                      </li>
                      <li> 
                        <a href={process.env.PUBLIC_URL} target="_blank" rel="noopener noreferrer">
                          <Icofont icon='youtube' />
                        </a> 
                      </li>
                      <li>
                        
                        <a href={process.env.PUBLIC_URL} target="_blank" rel="noopener noreferrer">
                          <Icofont icon='instagram' />
                        </a>
                      </li>
                  </ul>
                </div>
              </div>
              <p style={{ textAlign: 'justify', textJustify: 'inter-word', fontSize: '16px' }} className="mt-30">
                {project.details}
              </p>
            </div>
          </div>
        </div>
      </section>
      <BlogSlider /> 
      <CTAOne
        tagline="A Construction Company"
        title="Potohar Builders"
        textButton="Contact us"
        textLink="/contact"
        bg="color"
      >
        Potohar Builders is a name of prestigious and efficient developers of state-of-the-art designs.
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};

export default SinglePortfolio;
