import React from "react";

const PageTitlePortfolio = ({ project, tagline }) => (
  <section
    style={{ background: `url(${require("../../assets/images/apartment/rhodium-title.jpg")})`, backgroundSize: 'auto'}}
    className="title-hero-bg"
    data-stellar-background-ratio="0.2"
  >
    <div className="container">
      <div className="page-title text-center">
        <h1>{'Type '+ project.type}</h1>
        <h4 className="text-uppercase mt-30 white-color">{tagline}</h4>
      </div>
    </div>
  </section>
   
);

export default PageTitlePortfolio;
