import React from "react";
// import ContactFormSix from "./ContactFormSix";
import Icofont from "react-icofont";
import potoharLogo from "../../assets/images/andy-img.jpg";
import rhodiumLogo from "../../assets/images/logo-black.png";
// import Map from "../Maps/Map";

const ContactFour = () => (
  <section className="pt-0">
    <div className="container">
      <div className="row ">
        <div className="col-md-12 section-heading">
          <h2 className="text-uppercase">Contact Us</h2>
          <hr className="text-center yellow-bg" />
        </div>
        <div className="col-md-6 col-sm-12 mb-20">
          <div className="pricing-box  ">
            {/* <Icofont icon="google-map" className="font-50px white-icon" /> */}
            <center>
              <img
                src={potoharLogo}
                style={{ maxHeight: "100px" }}
                className="rounded-circle"
                alt="img-1"
              />
              <h4 className="">Potohar Builders</h4>
            </center>
            <hr className="text-center yellow-bg" />

            <p>
              <Icofont icon={`google-map`} className="font-20px white-icon" />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;&nbsp; 6-C Faiz Arcade, Jinnah Super, F-7 Markaz,
                Islamabad{" "}
              </span>
            </p>
            <p>
              <Icofont icon={`email`} className="font-20px white-icon" />
              <span>
                &nbsp;&nbsp;&nbsp;
                <a style={{ color: "black" }} href="mailto:contact@potohar.co">
                  info@potohar.co
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                <a style={{ color: "black" }} href="mailto:contact@potohar.co">
                  contact@potohar.co
                </a>
              </span>
            </p>
            <p>
              <Icofont icon={`iphone`} className="font-20px white-icon" />
              <span>
                &nbsp;&nbsp;&nbsp;{" "}
                <a style={{ color: "black" }} href="tel:+92 51 111 111 011">
                  +92 51 111 111 011
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                <a style={{ color: "black" }} href="tel:+92 300 60 88882">
                  +92 300 60 88882
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                <a style={{ color: "black" }} href="tel:+92 309 5550301">
                  +92 309 5550301
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="pricing-box ">
            {/* <Icofont icon="google-map" className="font-50px white-icon" /> */}
            <center>
              <img
                src={rhodiumLogo}
                style={{ maxHeight: "100px" }}
                className=" "
                alt="img-1"
              />
              <h4 className="">Site Office</h4>
            </center>
            <hr className="text-center yellow-bg" />

            <p>
              <Icofont icon={`google-map`} className="font-20px white-icon" />
              <span style={{ color: "black" }}>
                &nbsp;&nbsp;&nbsp; Plot # 11A, Diplomatic Enclave, Islamabad{" "}
              </span>
            </p>
            <p>
              <Icofont icon={`email`} className="font-20px white-icon" />
              <span>
                &nbsp;&nbsp;&nbsp;
                <a style={{ color: "black" }} href="mailto:contact@potohar.co">
                  info@potohar.co
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                <a style={{ color: "black" }} href="mailto:contact@potohar.co">
                  contact@potohar.co
                </a>
              </span>
            </p>
            <p>
              <Icofont icon={`iphone`} className="font-20px white-icon" />
              <span>
                &nbsp;&nbsp;&nbsp;{" "}
                <a style={{ color: "black" }} href="tel:+92 51 111 111 011">
                  +92 51 111 111 011
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                <a style={{ color: "black" }} href="tel:+92 300 60 88882">
                  +92 300 60 88882
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                <a style={{ color: "black" }} href="tel:+92 309 5550301">
                  +92 309 5550301
                </a>
              </span>
            </p>
          </div>
        </div>
        {/* <div className="col-md-4 feature-box text-center col-sm-6 mt-50" 
        >
          <p><Icofont
            icon={`google-map`}
            className="font-20px white-icon"
          /><span>&nbsp;&nbsp;&nbsp; 6-C Faiz Arcade, Jinnah Super, F-7 Markaz, Islamabad </span></p> 
          <p><Icofont
            icon={`google-map`}
            className="font-20px white-icon"
          /><span>&nbsp;&nbsp;&nbsp; 6-C Faiz Arcade, Jinnah Super, F-7 Markaz, Islamabad </span></p> 
          <p><Icofont
            icon={`google-map`}
            className="font-20px white-icon"
          /><span>&nbsp;&nbsp;&nbsp; 6-C Faiz Arcade, Jinnah Super, F-7 Markaz, Islamabad </span></p> 
          <h4 className="upper-case text-white">Office Address</h4>
          <p  className="text-white mt-30" >
            6-C Faiz Arcade, Jinnah Super, <br />  
                F-7 Markaz, Islamabad  
          </p> 
        </div>
        <div className="col-md-4 feature-box text-center col-sm-6 mt-50" 
        >
          <Icofont
            icon={`email`}
            className="font-40px dark-icon white-bg-icon circle-icon fade-icon"
          />
          <h4 className="upper-case text-white">Email Us</h4>
          <p className="text-white mt-30" >
            <a className="white-color" href="mailto:contact@potohar.co">info@potohar.co</a><br/>  
            <a className="white-color" href="mailto:contact@potohar.co">contact@potohar.co</a>
          </p>
          <p   className="text-white mt-30" >
            
          </p>
        </div>
        <div className="col-md-4 feature-box text-center col-sm-6 mt-50" 
        >
          <Icofont
            icon={`iphone`}
            className="font-40px dark-icon white-bg-icon circle-icon fade-icon"
          />
          <h4 className="upper-case text-white">Call Us</h4>
          <p   className="text-white mt-30" >
            <a href="tel:+92 309 5550301">+92 309 5550301</a><br/>
            <a href="tel:+92 300 60 88882">+92 300 60 88882</a><br/> 
            <a href="tel:+92 51 111 111 011">+92 51 111 111 011</a>
          </p> 
        </div> */}
        {/* <div className= "col-md-4 col-sm-12">
          <div className="pricing-box mt-100">
            <Icofont icon="google-map" className="font-50px white-icon" />
            <h3 className="">Address</h3>
            <br />
            <p style={{fontSize : '18px'}} >
              <sup>6-C Faiz Arcade,<br /> Jinnah Super, <br />
                  F-7 Markaz, Islamabad </sup> 
            </p>  
          </div>
        </div>
        <div className= "col-md-4 col-sm-12">
          <div className="pricing-box mt-100">
            <Icofont icon="email" className="font-50px white-icon" />
            <h3 className="">Email Us</h3>
            <br />
            <h5 style={{fontSize : '18px'}} >
              <sup>6-C Faiz Arcade,<br /> Jinnah Super, <br />
                  F-7 Markaz, Islamabad </sup> 
            </h5>  
          </div>
        </div>
        <div className= "col-md-4 col-sm-12">
          <div className="pricing-box mt-100">
            <Icofont icon="iphone" className="font-50px white-icon" />
            <h3 className="">Call Us</h3>
            <br />
            <h5 style={{fontSize : '18px'}} >
              <sup>6-C Faiz Arcade,<br /> Jinnah Super, <br />
                  F-7 Markaz, Islamabad </sup> 
            </h5>  
          </div>
        </div> */}
      </div>
    </div>
  </section>
  // <>
  //   {/* <section className="contact-us white-bg" id="contact">
  //     <div className="container">
  //       <div className="clearfix">
  //         <div className="bg-flex-right col-md-6 map-section">
  //           <Map />
  //         </div>
  //         <div className="col-about-left col-md-6 text-left">
  //           <h2>Get in Touch</h2>
  //           <h4 className="text-uppercase">Find your brand's voice</h4>
  //           <ContactFormSix />
  //         </div>
  //       </div>
  //     </div>
  //   </section> */}
  //   <section className="p-0">
  //     <div className="container-fluid">
  //       <div className="row row-flex">
  //         <div  className="col-md-4 col-sm-12 ">
  //           <div className="pricing-box">
  //             <h3 className="font-700">sdfg</h3>
  //             <h1 className="blue-color">
  //               <sup>sdfg</sup>
  //               <span>sdfg</span>
  //             </h1>
  //           <div className="pricing-box-bottom">
  //             <a href="!#" className="btn btn-color btn-circle">
  //               asd
  //             </a>
  //           </div>
  //         </div>
  //       </div>
  //         {/* <div className="col-md-4">
  //           <div className="col-inner spacer dark-bg">
  //             <div className="text-center white-color">
  //               <Icofont icon="google-map" className="font-50px white-icon" />
  //               <h2>Office Address</h2>
  //               <p>
  //                 6-C Faiz Arcade, Jinnah Super, <br />
  //                 F-7 Markaz, Islamabad
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="col-md-4">
  //           <div style={{backgroundColor : '#C88012'}} className="col-inner spacer ">
  //             <div className="text-center white-color">
  //               <Icofont icon="email" className="font-50px white-icon" />
  //               <h2>Email Us</h2>
  //               <p className="mb-0 white-color"><a className="white-color" href="mailto:contact@potohar.co">
  //               info@potohar.co

  //                 </a></p>
  //               <p className="white-color"><a className="white-color" href="mailto:info@potohar.co">
  //               contact@potohar.co
  //                 </a></p>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="col-md-4">
  //           <div className="col-inner spacer dark-bg">
  //             <div className="text-center white-color">
  //               <Icofont icon="iphone" className="font-50px white-icon" />
  //               <h2>Call Us</h2>
  //               <p className="mb-0"><a href="tel:+92 309 5550301">+92 309 5550301</a></p>
  //               <p className="mb-0"><a href="tel:+92 300 60 88882">+92 300 60 88882</a></p>
  //               <p className="mb-0"><a href="tel:+92 51 111 111 011">+92 51 111 111 011</a></p>
  //             </div>
  //           </div>
  //         </div> */}
  //       </div>
  //     </div>
  //   </section>
  // </>
);

export default ContactFour;
