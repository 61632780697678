import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderFive";
import dataNav from "../../data/Navbar/navbar-data.json";
import PageTitleAbout from "../../components/PageTitle/PageTitleAbout";
import WhoWeAreSeven from "../../components/WhoWeAre/WhoWeAreSeven";
// import OurTeamTwo from "../../components/Team/OurTeamTwo";
// import AboutArchitecture from "../../components/AboutUs/AboutArchitecture";
// import WelcomeTravel from "../../components/WelcomeSection/TravelAgency/WelcomeTravel";
// import Icofont from "react-icofont";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import img from "../../assets/images/singer-img.png";
// import GalleryConstruction from "../../components/Gallery/Construction/GalleryConstruction";
import TheProjects from "../../components/Testimonials/TestimonialsOne";
import CounterConstruction from "../../components/Counters/Construction/CounterConstruction";

export default function HomePage() {
  //const about = useRef();
  return (
    <Loader>
      <HeaderOne data={dataNav} />
      <PageTitleAbout title=" " tagline=" " />
      {/* <AboutArchitecture ref={about} />  */}
      <section id="about " className="light-bg">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 pt-80 pb-30 mt-50">
              <h2 className="font-500 ">Vision</h2>
              <div className="mt-30">
                <p className="">
                  {`Our vision is to become one of the esteemed real-estate companies all over Pakistan through our state-of-the-art designs and high level of reliability in delivering the promises we made to our valued clientele.`}
                </p>
              </div>
              <h2 className="font-500 mt-150 ">Mission</h2>
              <div className="mt-30">
                <p className="">
                  {`We’re dedicated to achieving our vision through building long-lasting relationships with our valued clients. We are determined to provide highly professional advice, prompt customer service, and on-time delivery in the real estate industry.`}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img className="img-fluid" src={img} alt="" />
            </div>
          </div>
        </div>
      </section>
      <WhoWeAreSeven />
      {/* <GalleryConstruction /> */}
      <TheProjects title="Our World Class Projects" tagline="" />
      <CounterConstruction />
      {/* <OurTeamTwo title="MEET OUR TEAM" tagline="WE ARE STRONGER" />  */}
      <ClientsBrand />
      <FooterOne />
    </Loader>
  );
}
