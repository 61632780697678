import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderFive";
import PageTitleAbout from "../../components/PageTitle/PageTitleAbout";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import { Box, Grid } from "@mui/material";
import dataNav from "../../data/Navbar/navbar-data.json";

const Highlights = () => (
  <Loader>
    <HeaderOne data={dataNav} />
    <PageTitleAbout
      title="Construction Updates"
      tagline="Rhodium Residencia | Luxury Apartments"
    />
    <Box py={5} my={5}>
      <Box className="container">
        <Grid
          container
          rowSpacing={{ xs: 3, md: 10 }}
          columnSpacing={{ xs: 3 }}
          alignItems="center"
        >



          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/uyNdErP_IcQ?si=xP7el859ujgAL3zm"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid display={{ xs: "block", md: "block" }} item xs={12} md={6}>
            <h3>
              18-August-2024 Construction Update | | Rhodium Residencia | Diplomatic Enclave | Islamabad
            </h3>
          </Grid>

          <Grid display={{ xs: "block", md: "block" }} item xs={12} md={6}>
            <h3>
              3rd Feburary 2024 Construction Update | Rhodium Residencia | Diplomatic Enclave | Islamabad
            </h3>
          </Grid>

          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/maJSOsSeYr8?si=a844uGdzrhm4FicP"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>



          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/9OzApFZlVEQ?si=-7pppmVmhKMGPbaA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid display={{ xs: "block", md: "block" }} item xs={12} md={6}>
            <h3>
              18 November 2023 Construction Update | Rhodium Residencia | Diplomatic Enclave | Islamabad
            </h3>
          </Grid>


          <Grid display={{ xs: "block", md: "block" }} item xs={12} md={6}>
            <h3>
              16th September 2023 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/vQZbXOpydUI?si=iMkaL3k3Glg_5_rf"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>

          <Grid display={{ xs: "block", md: "none" }} item xs={12} md={6}>
            <h3>
              14th August 2023 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/bsXTAH2ltEE?si=fb0FUjDOL9Qc0lPv"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid display={{ xs: "none", md: "block" }} item xs={12} md={6}>
            <h3>
              14th August 2023 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>
              10th June 2023 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/b0n_UxLKv2s?si=EY3PbGMCBKgXIwNG"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid display={{ xs: "block", md: "none" }} item xs={12} md={6}>
            <h3>
              9th April 2023 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/ad2Hmqet8OI?si=r_Qk2M3E3uJ5m2Eh"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid display={{ xs: "none", md: "block" }} item xs={12} md={6}>
            <h3>
              9th April 2023 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>
              13th February 2023 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/Bk-JoFyLBVM?si=LGx5S8FSST54uOcg"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>

          <Grid display={{ xs: "block", md: "none" }} item xs={12} md={6}>
            <h3>
              7th December 2022 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/2K6T82RhBHE?si=bm-lg9mU1z7VG4-t"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
          <Grid display={{ xs: "none", md: "block" }} item xs={12} md={6}>
            <h3>
              7th December 2022 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>
              2nd September 2022 Construction Update | Rhodium Residencia |
              Diplomatic Enclave | Islamabad
            </h3>
          </Grid>
          <Grid item xs={12} md={6} mb={{ xs: "40px", md: "0" }}>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/OVk0nN7_dX0?si=HZuhXKYUo6j83NVB"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
        </Grid>
      </Box>
    </Box>
    <ClientsBrand />
    <FooterOne />
  </Loader>
);

export default Highlights;
