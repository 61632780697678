import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FloatingWhatsApp from "react-floating-whatsapp";
import "react-floating-whatsapp/dist/index.css";
import HomePage from "./pages/website/HomePage";
import AboutPage from "./pages/website/About";
// import Projects from "./pages/website/Projects";
import Apartments from "./pages/website/Apartments";
import Location from "./pages/website/Location";
import Contact from "./pages/website/Contact";
import Highlights from "./pages/highlights/Highlights";
import SinglePortfolio from "./pages/portfolio/SinglePortfolio";
import SingleApartment from "./pages/apartments/SingleApartment";
import Page404 from "./pages/others/Page404";
import potoharlogo from "./assets/images/andy-img.jpg";
// import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  return (
    <Router basename={"/"}>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/about`}
          component={AboutPage}
        />
        {/* <Route exact path={`${process.env.PUBLIC_URL}/projects`} component={Projects}/>  */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/projects/:id`}
          component={SinglePortfolio}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/apartments`}
          component={Apartments}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/apartment/:id`}
          component={SingleApartment}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/location`}
          component={Location}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contact`}
          component={Contact}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/highlights`}
          component={Highlights}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/404-page`}
          component={Page404}
        />
        <Route component={Page404} />
      </Switch>
      <FloatingWhatsApp
        className="floating-whatsapp-fixed"
        accountName="Potohar Builders"
        phoneNumber="923006088882"
        statusMessage="online"
        avatar={potoharlogo}
      />
    </Router>
  );
}

export default App;
