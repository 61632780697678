import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderFive";
import dataNav from "../../data/Navbar/navbar-data.json";
import PageTitleApartments from "../../components/PageTitle/PageTitleApartments";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import Portfolio from "../../components/Portfolio/Portfolio";
import SeventhFloorApartments from "../../components/Portfolio/SeventhFloorApartments";
import ServicesConstruction from "../../components/Services/Construction/ServicesConstruction";

const Apartments = () => (
  <Loader>
    <HeaderOne data={dataNav} />
    <PageTitleApartments
      title="Apartments"
      tagline="Rhodium Residencia | Luxury Apartments"
    />

    <Portfolio filter="true" columns="3" layout="wide" space="true">
      <div className="col-sm-8 section-heading">
        <h2 className=" ">Rhodium Residencia Apartments</h2>
        <h7>Apartments from First Floor to Sixth Floor</h7>
      </div>
    </Portfolio>
    <SeventhFloorApartments
      filter="true"
      columns="3"
      layout="wide"
      space="true"
    >
      <div className="col-sm-8 section-heading">
        <h2 className=" ">Rhodium Residencia Apartments</h2>
        <h7>Floors range in this portion is from 7th to 14th</h7>
      </div>
    </SeventhFloorApartments>

    <ServicesConstruction />

    <ClientsBrand />
    <FooterOne />
  </Loader>
);

export default Apartments;
