import React from "react";
import ServicesConstruction from "../../components/Services/Construction/ServicesConstruction";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderSeven";
import dataNav from "../../data/Navbar/navbar-data.json";
import PageTitlePortfolio from "../../components/PageTitle/PageTitlePortfolio";
import CTAOne from "../../components/CTA/CTAOne";
import FooterOne from "../../components/Footer/FooterOne";
import dataGallery from "../../data/Portfolio/creative-agency-data";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#C88012",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#f2d8b0",
    color: "#3f3f3f",
    cursor: "pointer",
  },
  "&:nth-of-type(odd)": {
    color: theme.palette.common.black,
    cursor: "pointer",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    cursor: "pointer",
  },
}));

const SingleApartment = () => {
  const [project, setProject] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    setProject(dataGallery.find((item) => item.id === Number(id)));
  }, [id]);
  if (project === null) return <Loader />;

  return (
    <Loader>
      <HeaderOne data={dataNav} />
      <PageTitlePortfolio project={project} />
      <section className="pt-100 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <blockquote className="blockquote blockquote-custom">
                <div className="summary-cart">
                  <h6 className="upper-case">Apartment Details</h6>
                  <table className="order_table table-responsive table">
                    <tbody>
                      <tr>
                        <th>Apartment Type</th>
                        <td>
                          <h6>
                            <strong>{project.type}</strong>
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <th>No Of Beds</th>
                        <td>
                          <h6>
                            <strong>
                              {project.noOfBedrooms === "1"
                                ? project.noOfBedrooms + " Bed"
                                : project.noOfBedrooms + " Beds"}
                            </strong>
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <th>Floor</th>
                        <td>
                          <h6>
                            {project &&
                              project.floor.map((item, index) => (
                                <strong key={item.id}>
                                  <span>{item.floor}</span>
                                </strong>
                              ))}
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <th>{`Size (Sqft)`}</th>
                        <td>
                          <h6>
                            <strong>{project.size}</strong>
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </blockquote>
            </div>
            <div className="col-md-6">
              <div className="col-md-12">
                <div className="portfolio-slider">
                  <div className="item">
                    <img
                      className="img-fluid"
                      style={{ border: "solid 4px" }}
                      src={require("../../assets/images/" + project.imageLeft)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="col-md-12">
                <div className="portfolio-slider">
                  <div className="item">
                    <img
                      className="img-fluid"
                      style={{ border: "solid 4px" }}
                      src={require("../../assets/images/" +
                        project.imageLeftAlt)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Table
                style={{ border: "solid" }}
                size="small"
                className="table-striped responsive"
              >
                <TableHead>
                  <StyledTableCell className="text-white">Name</StyledTableCell>
                  <StyledTableCell className="text-white">Size</StyledTableCell>
                </TableHead>
                <TableBody>
                  {project &&
                    project.tableDetails.map((item, index) => (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell>{item.name}</StyledTableCell>
                        <StyledTableCell>{item.size}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        {project.versions.length > 0 &&
          project.versions.map((pv, index) => (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <blockquote className="blockquote blockquote-custom">
                      <div className="summary-cart">
                        <h6 className="upper-case">Apartment Details</h6>
                        <table className="order_table table-responsive table">
                          <tbody>
                            <tr>
                              <th>Floor</th>
                              <td>
                                <h6>
                                  {pv &&
                                    pv.floor.map((item, index) => (
                                      <strong key={item.id}>
                                        <span>{item.floor + ", "}</span>
                                      </strong>
                                    ))}
                                </h6>
                              </td>
                            </tr>
                            <tr>
                              <th>{`Size (Sqft)`}</th>
                              <td>
                                <h6>
                                  <strong>{pv.size}</strong>
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </blockquote>
                  </div>
                  <div className="col-md-4">
                    <div className="col-md-12">
                      <div className="portfolio-slider">
                        <div className="item">
                          <img
                            className="img-fluid"
                            style={{ border: "solid 4px" }}
                            src={require("../../assets/images/" + pv.imageLeft)}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="col-md-12">
                      <div className="portfolio-slider">
                        <div className="item">
                          <img
                            className="img-fluid"
                            style={{ border: "solid 4px" }}
                            src={require("../../assets/images/" +
                              pv.imageLeftAlt)}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4" style={{ marginBottom: "16px" }}>
                    <Table
                      style={{ border: "solid" }}
                      size="small"
                      className="table-striped responsive"
                    >
                      <TableHead>
                        <StyledTableCell className="text-white">
                          Name
                        </StyledTableCell>
                        <StyledTableCell className="text-white">
                          Size
                        </StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {pv &&
                          pv.tableDetails.map((pi, index) => (
                            <StyledTableRow key={pi.id}>
                              <StyledTableCell>{pi.name}</StyledTableCell>
                              <StyledTableCell>{pi.size}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </>
          ))}
        <div className="container mt-50">
          <div className="row">
            <div className="col-md-12 mt-30">
              <div className="item">
                <img
                  style={{ border: "solid" }}
                  className="img-fluid"
                  src={require("../../assets/images/" + project.mainImage)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ServicesConstruction />

      <CTAOne
        tagline="A Construction Company"
        title="Potohar Builders"
        textButton="Contact us"
        textLink="/contact"
        bg="color"
      >
        Potohar Builders is a name of prestigious and efficient developers of
        state-of-the-art designs.
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};

export default SingleApartment;
