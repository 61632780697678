import React from "react";
import Pdf from '../../assets/brochure.pdf';

const SliderButtons = ({buttons}) => {
    return (
    buttons.map((button) => (
      <a
        key={button.id}
        href={button.link === "pdf" ? Pdf : button.link}
        target="_blank"
        rel="noopener noreferrer"
        className={"btn btn-animate btn-sm " + (button.type ? button.type : "")}
      >
        <span>
          {button.text}
          <i className="icofont icofont-arrow-right"></i>
        </span>
      </a>
    ))
)};

export default SliderButtons;
