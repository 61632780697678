import React from "react";
import dataServices from "../../../data/Services/services-construction-data.json";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
// import SmartStudio from "../../../assets/images/apartment/smart-studio-bg-1.jpg"

const ServicesConstruction = () => (
  <section className="dark-bg">
    <div className="container">
      <div className="row">
        <div className="col-md-8 section-heading">
          <h2 className="white-color">The Apartments, So Luxurious</h2>

          <p
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              fontSize: "16px",
            }}
          >
            Life is not perfect. But your home can be. Rhodium Residencia
            doesn’t compromise on your comfort and luxurious lifestyle. A
            thoughtfully constructed edifice presents a place where comfort has
            been automated to serve you in the best possible manner. It offers
            spacious and luxurious apartments suitable for families of all
            sizes, for singles, couples, and families with children.
          </p>

          <hr className="text-center white-bg" />
        </div>
      </div>
      <div className="row service-box-style-01 mt-50">
        {dataServices.map((service) => (
          <div className="col-md-4 col-sm-4 col-xs-12" key={service.id}>
            <div className="flipper">
              <div className="text-center mb-50 main-box">
                <div
                  style={{
                    background: `url(${require("../../../assets/images/apartment/" +
                      service.image)}) center center / cover scroll no-repeat`,
                  }}
                  className="box-front height-300px white-bg"
                >
                  <div className="content-wrap">
                    <h3 className="font-400 text-white">{service.title}</h3>
                  </div>
                </div>
                <div
                  style={{
                    background: `url(${require("../../../assets/images/apartment/1.jpg")}) center center / cover scroll no-repeat`,
                  }}
                  className="box-back height-350px white-bg"
                >
                  <div className="content-wrap">
                    {/* <Icofont
                      icon={service.icon}
                      className="font-40px dark-color"
                    /> */}
                    <h3 className="font-600">{service.title}</h3>
                    <p
                      style={{ textAlign: "center", fontSize: "14px" }}
                      className="mt-20 dark-color"
                    >
                      {service.text}
                    </p>
                    {/* <a href="!#" className="btn btn-sm btn-white btn-square btn-animate mt-8">
                      <span>
                        Read more <Icofont icon="simple-right" />
                      </span>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <center>
        <Link
          to="/apartments"
          className="btn btn-sm btn-white btn-square btn-animate mt-8"
        >
          <span>
            View All Apartments
            <Icofont icon="simple-right" />
          </span>
        </Link>
      </center>
    </div>
  </section>
);

export default ServicesConstruction;
