import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderFive";
import dataNav from "../../data/Navbar/navbar-data.json";
import PageTitleLocation from "../../components/PageTitle/PageTitleLocation";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";

const Location = () => (
  <Loader>
    <HeaderOne data={dataNav} />

    <PageTitleLocation
      title="Location"
      tagline="Right in the Heart of the Capital"
    />
    <section className="first-ico-box">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 section-heading text-white">
            <h2>An Address, So Approachable</h2>
            <p
              style={{
                textAlign: "justify center",
                textJustify: "inter-word",
                fontSize: "16px",
              }}
            >
              Diplomatic Enclave
            </p>
            <h7 style={{ color: "#767d89" }}>
              {" "}
              Islamabad – A carefully chosen capital for the project; declared
              as the second most beautiful capital in the world. It is eminently
              developed and organized in such a way that it can be observed
              through any dimension. The city is known for its multiculturalism
              and celebrates modernism without compromising on environmentalism.
            </h7>
          </div>
        </div>
        <div class="mapouter">
          <div className="gmap_canvas">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              className="gmap_iframe"
              height={800}
              width={"100%"}
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=800&amp;height=800&amp;hl=en&amp;q=Rhodium Resi&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
    {/* <section className="p-0">
        <div className="container-fluid">
          <div className="row row-flex">
            <div className="col-md-6">
              <div className="col-inner spacer dark-bg">
                <div className="text-center white-color">
                  <Icofont icon="google-map" className="font-50px white-icon" />
                  <h2>Site Address</h2>
                  <h6> - </h6>
                  <h6> Let us carve Rhodium to sculpt an elysium lifestyle. </h6>
                  <br/>
                  <p>
                    Plot # 11A <br />Ramna 4 Diplomatic Enclave,<br />
                    Islamabad, Islamabad Capital Territory
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{backgroundColor : '#C88012'}} className="col-inner spacer ">
                <div className="text-center white-color">
                  <Icofont icon="google-map" className="font-50px white-icon" />
                  <h2>Office Address</h2>
                  <h6>Head Office | Potohar Builders 
                  </h6>
                  <h6> 
                    We are a leading real estate development company
                  </h6>
                  <br/>
                  <p>
                    6-C Faiz Arcade,<br /> Jinnah Super, <br />
                    F-7 Markaz, Islamabad, Pakistan
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>  */}
    <ClientsBrand />
    <FooterOne />
  </Loader>
);

export default Location;
