import React from "react";
// import ReactWow from "react-wow";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";

const PortfolioItem = ({
  id,
  title,
  category,
  image,
  links,
  space,
  groups,
  type,
  columns,
  openLightbox,
  range
}) => {
  return (
    // <ReactWow animation="fadeIn">
      <div
        className={
          "portfolio-item col-md-" + 4 +
          (type === "product" ? " portfolio-masonry-item " : " ") +
          (space === "true" ? "gutter-space" : "no-gutter")
        }
        data-wow-delay="0.2s"
        data-groups={
          groups ? "[" + groups.map((val) => `"${val}"`) + "]" : null
        }
      >
        {/* <ReactWow animation="fadeIn"> */}
          <div
            className={
              "portfolio gallery-image-hover " +
              (type === "masonry" ? "portfolio-masonry-item" : "")
            }
          >
            <div className="dark-overlay"></div>
            <img style={{border :'solid 4px'}} src={require("../../assets/images/" + image)} alt="" />
            <div className="portfolio-wrap">
              <div className="portfolio-description">
                <h3 className="portfolio-title">{title}</h3>
                <p className="portfolio-title">{range}</p>
              </div>
              <ul className="portfolio-details">
                <li className="links">
                {category.toUpperCase()}&nbsp;&nbsp;&nbsp;
                </li>
                <li>
                  <a
                    className="alpha-lightbox"
                    href="!#"
                    onClick={(e) => openLightbox(e, image)}
                  >
                    <Icofont icon="search-1" />
                  </a>
                </li> 

                <br/>
                  <br/>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/apartment/${id}`} className="btn btn-sm btn-color btn-square btn-animate mt-8">
                  <span>
                      View Apartment <Icofont icon="simple-right" />
                  </span>
                </Link> 
                </li> 
              </ul>
              
            </div>
          </div>
        {/* </ReactWow> */}
      </div>
    // </ReactWow>
  );
};

export default PortfolioItem;
