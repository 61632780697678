import React from "react";
// import Icofont from "react-icofont";

const AppStyle = ({ detail, title, image }) => {
  return (
    <div className="testimonial-item">
      <div
        style={{
          background: `url(${require("../../assets/images/" +
            image)}) center center / cover scroll no-repeat`,
          border: "solid white",
          minHeight: "580px",
          marginTop: "80px",
        }}
      ></div>
      <br />
      <br />
    </div>
  );
};

export default AppStyle;
