import React, { useEffect } from "react";
import Loader from "./../../components/Loader/Loader";
import dataNav from "../../data/Navbar/navbar-data.json";
import AOS from "aos";
import "aos/dist/aos.css";
//import Portfolio from "../../components/Portfolio/Portfolio";
import dataSlider from "../../data/Slider/construction-data.json";
import HeaderOne from "../../components/Header/HeaderFive";
import ConstructionSlider from "../../components/Hero/Construction/ConstructionSlider";
import TheAppartments from "../../components/Services/Construction/ServicesConstruction";
import image from "../../assets/images/architecture-img.jpg";
// import vid from "../../assets/video.mp4";
// import GalleryConstruction from "../../components/Gallery/Construction/GalleryConstruction";
// import CounterConstruction from "../../components/Counters/Construction/CounterConstruction";
import BlogSlider from "../../components/Blog/BlogSlider";
import ContactFour from "../../components/ContactUs/ContactFour";
import FsLightbox from "fslightbox-react";
// import WeAreRhodiumResidencia from "../../components/AboutUs/AboutArchitecture";
// import WeddingsStory from "../../components/Story/WeddingsStory";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
// eslint-disable-next-line no-unused-vars
import HeroSliderCombined from "../../components/Hero/HeroSliderCombined";
import FooterOne from "../../components/Footer/FooterOne";
// import WelcomeTravel from "../../components/WelcomeSection/TravelAgency/WelcomeTravel";
// import HeadingSection from "../../components/HeadingSection/HeadingSection";
import ADESIGN from "../../components/WelcomeSection/TravelAgency/WelcomeItem";
import AboutAppStyle from "../../components/AboutUs/AboutAppStyle";
// import AboutGym from "../../components/GymFitness/AboutGym";
import AboutUsOne from "../../components/AboutUs/AboutUsOne";
import { useState } from "react";
// import TheAmenities from "../../components/Testimonials/TestimonialsOne";
// import AboutTravel from '../../components/AboutUs/TravelAgency/AboutTravel';

const HomePage = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const [toggler, setToggler] = useState(false);
  return (
    <Loader>
      <FsLightbox toggler={toggler} sources={["video.mp4"]} type="video" />
      <HeaderOne data={dataNav} />
      <ConstructionSlider data={dataSlider} />
      <AboutUsOne
        title="We are Rhodium Residencia"
        image={image}
        toggler={toggler}
        setToggler={setToggler}
      >
        <p
          style={{ textAlign: "justify", textJustify: "inter-word" }}
          className="mt-50"
        >
          Rhodium Residencia - a new vertical of luxury and exceptional
          development where all amenities are spread out and exclusivity has a
          clout. A contemporary design that will never go out of style. It is
          situated in Diplomatic Enclave, at the heart of an exquisitely green
          Capital. An ideal setting as only countable capital cities in the
          world are blessed with such proximity to nature.
        </p>

        <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
          Rhodium Residencia offers a cluster of highly luxurious living options
          including lavish apartments with all the facilities that a
          contemporary city can offer. This remarkable project is meticulously
          structured keeping longevity in consideration. The ultimate design has
          no details that are left to be considered. A place that one can flaunt
          as a perfect abode. Each apartment makes use of design excellence to
          maximize the utilization of space in terms of lifestyle needs and
          aesthetics. The panoramic view of Margalla hills from the site is
          immensely appealing to the eyes.
        </p>
      </AboutUsOne>
      {/* <WeAreRhodiumResidencia  />   */}
      {/* <TheAmenities title="Our World Class Projects" tagline=" " /> */}
      <AboutAppStyle />
      <ADESIGN />
      <TheAppartments />
      {/* <WeddingsStory />  */}
      {/* <Portfolio columns="4" layout="wide" filter="true">
      <HeadingSection title="The Apartments, So Luxurious" tagline="Life is not perfect. But your home can be. Rhodium Residencia doesn’t compromise on your comfort and luxurious lifestyle. A thoughtfully constructed edifice presents a place where comfort has been automated to serve you in the best possible manner. It offers spacious and luxurious apartments suitable for families of all sizes, for singles, couples, and families with children."/>
    </Portfolio>  */}
      {/* <WelcomeTravel />  */}
      {/* <GalleryConstruction />
    <CounterConstruction /> */}
      <BlogSlider />
      <ContactFour />
      <ClientsBrand />
      <FooterOne />
    </Loader>
  );
};

export default HomePage;
