import React from "react";
import parse from "html-react-parser";
import imgGroom from "../../assets/images/andy-img.jpg";
import { Link } from "react-router-dom";

const CTAOne = ({ title, tagline, bg, textButton, linkButton, children }) => (
  <section
  style={{ backgroundColor : '#7B1D6B'}}
    className= "pt-50 pb-50 " >
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <div className="cta-heading-left">
            <img src={imgGroom} style={{ maxHeight : '100px'}} className="rounded-circle" alt="img-1" />
            {tagline && <p className="subtitle mt-20">{tagline}</p>}
            {title && <h3>{title}</h3>}
          </div>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-6">
          <div className="cta-heading-right">
            <p className="mt-20 content-text">{children && parse(children)}</p>
            <p className="mt-50">
              <Link
                className={
                  "btn btn-rounded " +
                  (bg && bg === "dark" ? "btn-color" : "btn-white")
                }
                to='/contact'
              >
                {textButton}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default CTAOne;
