import React from "react";
import HeadingSection from "../HeadingSection/HeadingSection";
import potoharLogo from "../../assets/images/andy-img.jpg";
import sardarLogo from "../../assets/images/anna-img.jpg";
import novaLogo from "../../assets/images/nova.jpg";
import mujahidLogo from "../../assets/images/mujahid.jpg";
import fazalLogo from "../../assets/images/fazal.jpg";

const WhoWeAreSeven = () => (
  <section className="dark-bg first-ico-box">
    <div className="container">
      <div className="row">
        <HeadingSection title="Who We Are" tagline="About Us"></HeadingSection>
        <h7 style={{ textAlign: "justify", textJustify: "inter-word" }}>
          Potohar Builders & Sardar Group of companies are the two prominent and
          reliable names to believe in while endeavoring to have international
          standards of living in a heterogeneous vibrant city. Both the renowned
          developers have successfully delivered iconic and ultra-modern real
          estate projects inclusive of The Centaurus, Silver Oaks, and Maroof
          International Hospital and have devised yet another exceptional
          landmark project named, “Rhodium Residencia” in the most secure area
          of the capital, The Diplomatic Enclave. For this luxurious residential
          project, the group collaborated with Fazal Steel and Mujahid
          Industries.
        </h7>
      </div>
      <div className="row mt-50">
        <div className="col-xl-3 col-sm-12">
          <center>
            <img
              src={potoharLogo}
              style={{ maxHeight: "100px" }}
              className="rounded-circle"
              alt="img-1"
            />
            <h4 className="text-white mt-30">Potohar Builders</h4>
          </center>
        </div>
        <div className="col-xl-2 col-sm-12">
          <center>
            <img
              src={sardarLogo}
              style={{ maxHeight: "100px" }}
              className="rounded-circle"
              alt="img-1"
            />
            <h4 className="text-white mt-30">Sardar Group of Companies</h4>
          </center>
        </div>
        <div className="col-xl-2 col-sm-12">
          <center>
            <img
              src={fazalLogo}
              style={{ maxHeight: "100px" }}
              className="rounded-circle"
              alt="img-1"
            />
            <h4 className="text-white mt-30">Barkat Rice</h4>
          </center>
        </div>
        <div className="col-xl-2 col-sm-12">
          <center>
            <img
              src={novaLogo}
              style={{ maxHeight: "100px" }}
              className="rounded-circle"
              alt="img-1"
            />
            <h4 className="text-white mt-30">Nova Group of Companies</h4>
          </center>
        </div>
        <div className="col-xl-3 col-sm-12">
          <center>
            <img
              src={mujahidLogo}
              style={{ maxHeight: "100px" }}
              className="rounded-circle"
              alt="img-1"
            />
            <h4 className="text-white mt-30">Mujahid Group of Industries</h4>
          </center>
        </div>
      </div>
    </div>
  </section>
);

export default WhoWeAreSeven;
