import React from "react";
import Loader from "../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderFive";
import dataNav from "../../data/Navbar/navbar-data.json";
import PageTitleContact from "../../components/PageTitle/PageTitleContact";
import ContactFour from "../../components/ContactUs/ContactFour";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import FooterOne from "../../components/Footer/FooterOne";
import ContactUs from "../../components/ContactUs/ContactUs";

// import Icofont from "react-icofont";

const Contact = () => (
  <Loader>
    <HeaderOne data={dataNav} />
    <PageTitleContact title="Contact" tagline="Find us here" />
    <ContactFour />
    <ContactUs />
    {/* <section className="p-0">
        <div className="container-fluid">
          <div className="row row-flex">
            <div className="col-md-6">
              <div className="col-inner spacer dark-bg">
                <div className="text-center white-color">
                  <Icofont icon="google-map" className="font-50px white-icon" />
                  <h2>Site Address</h2>
                  <h6> - </h6>
                  <h6> Let us carve Rhodium to sculpt an elysium lifestyle. </h6>
                  <br/>
                  <p>
                    Plot # 11A <br />Ramna 4 Diplomatic Enclave,<br />
                    Islamabad, Islamabad Capital Territory
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{backgroundColor : '#C88012'}} className="col-inner spacer ">
                <div className="text-center white-color">
                  <Icofont icon="google-map" className="font-50px white-icon" />
                  <h2>Office Address</h2>
                  <h6>Head Office | Potohar Builders 
                  </h6>
                  <h6> 
                    We are a leading real estate development company
                  </h6>
                  <br/>
                  <p>
                    6-C Faiz Arcade,<br /> Jinnah Super, <br />
                    F-7 Markaz, Islamabad, Pakistan
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>  */}
    <ClientsBrand />
    <FooterOne />
  </Loader>
);

export default Contact;
